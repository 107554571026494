<!--  -->
<template>
    <div class='page-mistakes-summary flex-center'>
        <component-bcg url="mistakes/c1.png"></component-bcg>
        <!-- <div class="summary-item head text-center">
            <span class="text-normal-a bold">{{pageText.t1}}</span>
        </div> -->
        <div class="summary-item content">
            <div class="content-body">
                <div class="content-head text-center">
                    <span class="text-normal-c bold">共{{noteCount}}题</span>
                </div>
                <div class="content-data">
                    <div class="data-item a">
                        <div class="a-i">
                            <span class="text-normal bold">{{pageText.t2}}</span>
                        </div>
                        <div class="a-i">
                            <span class="text-normal color bold">{{addedNoteCount}}</span>
                            <span class="text-normal bold">题</span>
                        </div>
                    </div>
                    <div class="data-item b">
                        <div class="a-i">
                            <span class="text-normal bold">{{pageText.t3}}</span>
                        </div>
                        <div class="a-i">
                            <span class="text-normal color bold">{{reviewedNoteCount}}</span>
                            <span class="text-normal bold">题</span>
                        </div>
                    </div>
                </div>
                <div class="content-foot">
                     <!--  -->
                    <dia-button class="foot-button-de" type="confirm" shape="square" :disabled="actionDisabled" @click="gotoExercise">
                        <!-- <span class="text-normal text-default-color">{{pageText.t4}}</span> -->
                        <img :src="`${PARAMS.assetsUrlV2_0}/mistakes/c3.png`" class="ft-img scale-button">
                    </dia-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import apiClass from '@/API/page/mistakes'

    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                info: {}
            };
        },
        methods: {
            pageInit(){
                this.InfoLoad()
            },
            async InfoLoad(){
                let {usercode: user_code} = this
                let res = await this.api.wrongProgress({user_code})
                if(res) this.info = res
            },
            gotoExercise(){
                if(this.actionDisabled) return
                let name = 'mistakes-exercise'
                let params = {exerciseId: -1}
                this._routeHand.goto({name, params})
            },
        },
        created() {
            this.pageInit()
        },
        components: {
        },
        computed: {
            actionDisabled(){
                return this.noteCount <= 0
            },
            noteCount(){
                let {noteCount = 0} = this.info || {}
                return noteCount
            },
            addedNoteCount(){
                let {addedNoteCount = 0} = this.info || {}
                return addedNoteCount
            },
            reviewedNoteCount(){
                let {reviewedNoteCount = 0} = this.info || {}
                return reviewedNoteCount
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-mistakes-summary {
        color: #726661;
        // width: 630px;
        // height: 352px;
        // margin: 15px 0px 0px 10px;
        // padding-top: 77px;
        // .background-img-max(url('@{assetsUrlV2_0}/mistakes/c4.png'));
        // padding-right: @app-padding-side;
        // width: 100vw;
        height: 100vh;

        .summary-item.head {
            padding-top: 5px;
            color: #FFFFFF;
        }

        .summary-item.content {
            // width: 277px;
            // margin-left: 189px;
            position: relative;
            width: 630px;
            height: 352px;
            .background-img-max(url('@{assetsUrlV2_0}/mistakes/c4.png'));

            // margin-top: 37px;
            // margin-top: calc(91px - @app-padding-head);
            .content-body {
                width: 227px;
                height: 271px;
                border-radius: 12px;
                margin: 0px auto;
                margin-top: 77px;

                // background: #fff;
                // padding: 0px 20px 32px;
                .content-head {
                    height: 16px;
                    line-height: 16px;
                    border-bottom: 1px solid #F3F3F3;
                }

                .content-data {
                    padding-top: 53px;

                    .data-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0px 2px 6px;
                        background: url('@{assetsUrlV2_0}/mistakes/c2.png') no-repeat;
                        background-size: 100% 2px;
                        background-position: bottom left;

                        .color {
                            margin-right: 2px;
                            transform: scale(1.05);
                        }
                    }

                    .data-item+.data-item {
                        margin-top: 17px;
                    }
                }

                .content-foot {
                    width: 170px;
                    height: 42px;
                    position: absolute;
                    bottom: 36px;
                    left: 246px;

                    .foot-button-de {
                        width: 100%;
                        height: 100%;
                        // transform: translateX(5px);
                        border: 0px;
                        background: none;

                        // .background-img-max(url('@{assetsUrlV2_0}/mistakes/c3.png'));
                        .ft-img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

    }
</style>